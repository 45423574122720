.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.address-wrap {
  display: flex;

  .zaddress {
    margin-left: 30px
  }

  @media (max-width:576px) {
    display: block;

    .zaddress {
      margin-left: 0
    }

  }
}
.news_right_box1 h3{
  font-size: 20px;
  line-height: 120%;
  margin: 0 20px 20px 20px;
}

.products-area .pro-sliders .products {
  text-align: left;

  .contents {
    h3 {
      font-size: 18px;
      a{
        color: #111;
      }
    }
    span{
      font-size: 16px;
      margin: 0 0 5px;
    }
    .btn4{
      margin-top: 20px;
      -webkit-clip-path: polygon(13% 0%, 100% 0, 87% 100%, 0% 100%);
      clip-path: polygon(13% 0%, 100% 0, 87% 100%, 0% 100%);
    }
  }

}

.shop-page .products,
.blog-pages .blog-list {
  &:hover {
    figure {
      border: 1px solid #878244;
      border-bottom: none;

      img {
        transform: translateX(-50%) translateY(-50%) scale(1.05) rotate(0deg);
      }
    }
  }

  figure {
    border: 1px solid #eee;
    border-bottom: none;
    transition: all 0.3s;
    padding-bottom: 100%;

    &:hover {
      img {
        transform: translateX(-50%) translateY(-50%) scale(1.05) rotate(0deg);
      }
    }

    img {
      position: absolute;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      padding: 10px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .contents {
    height: 250px;
    padding: 20px 15px;
    text-align: left;

    .cate {
      font-size: 15px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
      line-height: 120%;
      a{
        color: #111;
      }
    }

    span {
      font-size: 16px;
      margin: 0 0 5px
    }

    .btn4 {
      margin-top: 20px;
      -webkit-clip-path: polygon(13% 0%, 100% 0, 87% 100%, 0% 100%);
      clip-path: polygon(13% 0%, 100% 0, 87% 100%, 0% 100%);
    }
  }
}

.product-details {
  .single-product-content {
    .stock {
      margin-bottom: 15px;
    }

    .price {
      strong {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;

        &.normal {
          color: #666;
        }
      }
    }
  }

  .con {
    ul {
      margin-bottom: 25px;
    }
  }
}

.blog-details {
  &.news {
    .blogs .content-post .content ul li {
      display: block;

      &:first-child {
        padding: 0 15px;
      }
    }

  }

  .date {
    margin-bottom: 15px;
  }
}

.blog-pages.news .blog-list {
  position: relative;

  &:hover {
    .content {
      border: 1px solid #878244;
    }
  }

  .content {
    border: 1px solid #eee;
    height: 200px;

    .sub-title {
      width: 90%;
      margin-bottom: 15px;
    }

    h3 {
      width: 90%;
    }
  }

  .date {
    position: absolute;
    top: 0px;
    right: 0px;
    background: #878244;
    width: 55px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    padding: 5px 0;

    strong {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      display: block;
      line-height: 26px;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      display: block;
      text-transform: uppercase;
      line-height: 26px;
    }
  }
}

.contact-area {
  .form-area {
    padding: 0 10%;

    .feld {
      margin-bottom: 15px;
    }

    form fieldset .feld input[type=radio] {
      width: 15px;
      height: 15px;
    }
  }

}

.th-accordion {

  font-family: Arial, "微軟正黑體", sans-serif;
  background: #fff;
  transition: .5s;

  dt {
    padding: 15px;
    border-bottom: 1px solid #ccc;

    &:hover,
    &.active {
      cursor: pointer;
      transition: .5s;
      color: #878244;
    }
  }

  dd {

    padding: 15px;
    border-bottom: 1px solid #ccc;
    .status-tag {
      display: inline-block;
      width: 85px;
      text-align: center;
      color: #333;
      border-right: 1px solid #999;
      margin-left: 5px;
      margin-right: 10px;
      background-color: #878244;
      color: #fff;
      text-align: center;
    }

    .red-tag {
      color: #878244;
    }
  }

  tbody tr td {
    padding: 15px 10px;
    vertical-align: middle;
  }
}

.shop_table {
  thead {
    background-color: #efefef;
  }

  img {
    display: block;
    width: 80px;
  }
}
.twzipcode-wrap{

  select.city.form-control {
    margin-right: 10px;
  }
  .register_left_form select{
    margin-bottom: 0;
  }

}
 
.mt-50{
  margin-top: 50px;
}
